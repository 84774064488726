// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2015-js": () => import("./../../../src/pages/2015.js" /* webpackChunkName: "component---src-pages-2015-js" */),
  "component---src-pages-2016-js": () => import("./../../../src/pages/2016.js" /* webpackChunkName: "component---src-pages-2016-js" */),
  "component---src-pages-2017-js": () => import("./../../../src/pages/2017.js" /* webpackChunkName: "component---src-pages-2017-js" */),
  "component---src-pages-2018-js": () => import("./../../../src/pages/2018.js" /* webpackChunkName: "component---src-pages-2018-js" */),
  "component---src-pages-2020-js": () => import("./../../../src/pages/2020.js" /* webpackChunkName: "component---src-pages-2020-js" */),
  "component---src-pages-2021-js": () => import("./../../../src/pages/2021.js" /* webpackChunkName: "component---src-pages-2021-js" */),
  "component---src-pages-2022-js": () => import("./../../../src/pages/2022.js" /* webpackChunkName: "component---src-pages-2022-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

